import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React, { useContext } from "react";
import { AppContext } from "./AppContext";
import { makeStyles } from "@material-ui/core/styles";
import AutorenewIcon from "@material-ui/icons/Autorenew";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const MainTableAppBar = () => {
  const classes = useStyles();
  const { ctxPlannen, ctxSetTableFilter } = useContext(AppContext);

  const resetTableFilter = () => {
    debugger;
    ctxSetTableFilter([]);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: window.Color }}>
      <Toolbar variant="dense">
        <Typography variant="button" className={classes.title}>
          Aantal plannen: {ctxPlannen.length}
        </Typography>
        <AutorenewIcon
          fontSize="small"
          style={{ cursor: "pointer" }}
          onClick={resetTableFilter}
        ></AutorenewIcon>
      </Toolbar>
    </AppBar>
  );
};

export default MainTableAppBar;
