import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import esriConfig from "@arcgis/core/config.js";
import "@arcgis/core/assets/esri/themes/light/main.css";
import ApplicationHeader from "./ApplicationHeader";
import Typography from "@material-ui/core/Typography";
import MainMap from "./MainMap.js";
import MainTable from "./MainTable";
import Edit from "./Edit";
import MainTableAppBar from "./MainTableAppBar";
import NewPlanDialog from "./NewPlanDialog";
import { AppContext } from "./AppContext";
import { checkCurrentStatus, initialize, signIn, signOut } from "./Auth";
import { searchWebmap } from "./SearchWebmap";
import { deletePlan } from "./DeletePlan";
import { layerRefresh } from "./LayerRefresh";
import ModalDialog from "./ModalDialog";
import { ApplicationSession } from "@esri/arcgis-rest-auth";
import { UserSession } from "@esri/arcgis-rest-auth";
import { queryFeaturesUrl } from "./QueryFeatures";
import MessageBox from "./MessageBox";

function App() {
  esriConfig.assetsPath = "./assets";

  const [viewMode, setViewMode] = useState("Main"); //de state waarin het scherm verkeert: hoofdscherm, editscherm, nieuw
  const [newPlanDialog, setNewPlanDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataValid, setDataValid] = useState(false);
  const [openMessageBox, setOpenMessageBox] = React.useState(false);

  const {
    ctxCredentials,
    ctxSetCredentials,
    ctxPlannen,
    ctxSetPlannen,
    ctxSelectedPlan,
    ctxSetSelectedPlan,
    ctxWebMapId,
    ctxSetWebMapId,
    ctxFeaturelayerUrl,
    ctxFeaturelayer,
    ctxDetailTableUrl,
    ctxUserSession,
    ctxSetUserSession,
    ctxSetTableFilter,
    ctxSetTooltips,
    ctxSettings,
  } = useContext(AppContext);

  useEffect(() => {
    console.log("Versie: " + window.Versie);
    init();
  }, []);

  useEffect(() => {
    LoadTooltips();
  }, [ctxSettings]);

  async function init() {
    await initialize(window.AppID, window.PortalUrl);

    try {
      const cred = await checkCurrentStatus();
      if (cred) {
        console.log("Auto login:", cred);
        ctxSetCredentials(cred);

        // //voor de ArcGIS REST JS hebben we een usersession nodig
        const usersession = new UserSession.fromCredential(cred);
        ctxSetUserSession(usersession);

        //vanaf 1.10 gebruiken een OrganisationID uit de config
        //search the one webmap for the loggedin user
        searchWebmap(window.SharingUrl, ctxSetWebMapId, cred.token);
      }
    } catch (e) {
      console.log(e);
      try {
        const cred = await signIn();
        console.log("Logged In:", cred);
        ctxSetCredentials(cred);

        //voor de ArcGIS REST JS hebben we een usersession nodig
        const usersession = new UserSession.fromCredential(cred);
        ctxSetUserSession(usersession);

        //vanaf 1.10 gebruiken een OrganisationID uit de config
        //search the one webmap for the loggedin user
        searchWebmap(window.SharingUrl, ctxSetWebMapId, cred.token);
      } catch (e) {
        console.log(e);
      }
    }
  }

  const LoadTooltips = () => {
    if (ctxUserSession == undefined) return;
    queryFeaturesUrl(ctxSettings["tooltips"], ctxUserSession, "1=1", 0)
      .then((result) => {
        console.log("Tooltips: ", result);
        let tooltips = [];
        for (let index = 0; index < result.features.length; index++) {
          tooltips.push([
            result.features[index].attributes.veldnaam,
            result.features[index].attributes.tooltip,
          ]);
        }
        ctxSetTooltips(tooltips);
      })
      .catch((e) => {
        console.log("Tooltips failed: ", e);
      });
  };

  async function logOut() {
    await signOut();
    ctxSetCredentials(null);
  }

  const buttonTableClicked = (evt) => {
    console.log("buttonTableClicked: " + evt.currentTarget.id);
    switch (evt.currentTarget.id) {
      case "view":
        if (ctxSelectedPlan) {
          setViewMode("View");
        }
        break;
      case "add":
        ctxSetSelectedPlan(null);
        setNewPlanDialog(true);
        break;
      case "modify":
        if (ctxSelectedPlan) {
          setViewMode("Modify");
        }
        break;
      case "delete":
        if (ctxSelectedPlan) {
          setOpenModal(true);
        }
        break;
      default:
        break;
    }
  };

  const closeNewPlanDialog = (feature) => {
    setNewPlanDialog(false);
    if (feature != null) {
      ctxSetSelectedPlan(feature);
      setOpenMessageBox(true);
      setViewMode("Modify");
    }
  };

  const responseModalDialog = (value) => {
    console.log("Plan verwijderen: ", value);
    setOpenModal(false);
    if (value && ctxSelectedPlan) {
      deletePlan(
        ctxSelectedPlan.attributes,
        ctxFeaturelayerUrl,
        ctxDetailTableUrl,
        ctxUserSession,
        callback
      );
    }
  };
  const callback = () => {
    //Plan uit tabel verwijderen
    var plannen = ctxPlannen.filter((plan) => {
      return (
        plan.attributes[window.Objectid] !=
        ctxSelectedPlan.attributes[window.Objectid]
      );
    });
    ctxSetPlannen(plannen);
    ctxSetSelectedPlan(null);
    ctxSetTableFilter([]);
    layerRefresh(ctxFeaturelayer);
    setDataValid(false);
    setDataValid(true);
  };
  const buttonExitEditScherm = () => {
    ctxSetSelectedPlan(null);
    setViewMode("Main");
  };

  useEffect(() => {}, [
    ctxPlannen,
    ctxSelectedPlan,
    ctxWebMapId,
    ctxFeaturelayerUrl,
  ]);

  const buttonsignin = () => {
    init();
  };

  const responseMessageBox = (value) => {
    setOpenMessageBox(false);
  };

  return (
    <div>
      {ctxCredentials !== null ? (
        <div className="App" style={{ height: "99vh" }}>
          <br />
          {viewMode === "Main" ? (
            <div>
              <ApplicationHeader
                position="static"
                viewmode={viewMode}
                title={"Planregistratie Wonen"}
                buttonexitclicked={buttonExitEditScherm}
                buttonlogoutclicked={() => logOut()}
              />
              <div className="Maindiv">
                <div className="Maindivchild" style={{ width: "60%" }}>
                  <MainTableAppBar></MainTableAppBar>
                  <MainTable
                    buttontableclicked={() => buttonTableClicked}
                    dataValid={dataValid}
                  ></MainTable>
                </div>
                <div className="Maindivchild" style={{ width: "40%" }}>
                  <MainMap></MainMap>
                </div>
              </div>
              <ModalDialog
                open={openModal}
                title={"Plan verwijderen?"}
                question="Weet u zeker dat u dit plan wilt verwijderen?"
                handleClose={responseModalDialog}
              ></ModalDialog>
            </div>
          ) : (
            <div>
              <ApplicationHeader
                position="static"
                viewmode={viewMode}
                title={
                  "Planregistratie Wonen" +
                  " - " +
                  ctxSelectedPlan.attributes["plannaam"]
                }
                buttonexitclicked={buttonExitEditScherm}
              />
              <Edit viewmode={viewMode}></Edit>
            </div>
          )}
          <NewPlanDialog
            open={newPlanDialog}
            closedialog={closeNewPlanDialog}
          ></NewPlanDialog>
        </div>
      ) : (
        <Typography variant="h6">
          Planregistratie Wonen{" "}
          <button
            style={{ width: "100px", height: "30px" }}
            onClick={buttonsignin}
          >
            Inloggen
          </button>
        </Typography>
      )}
      <MessageBox
        open={openMessageBox}
        title={"Plan aangemaakt"}
        question={"Het plan is aangemaakt en kan nu verder ingevuld worden."}
        handleClose={responseMessageBox}
      ></MessageBox>
    </div>
  );
}

export default App;
