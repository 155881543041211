import React, { useRef, useEffect, useState, useContext } from "react";
import "./App.css";
import WebMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";
import Editor from "@arcgis/core/widgets/Editor";
import LayerList from "@arcgis/core/widgets/LayerList";
import Expand from "@arcgis/core/widgets/Expand";
import { AppContext } from "./AppContext";

export function EditMap(props) {
  const mapRef = useRef();
  const [mapview, setMapView] = useState(null);
  const mapviewRef = useRef(null);
  const { ctxWebMapId, ctxSelectedPlan, ctxGemeenteExtent } =
    useContext(AppContext);

  useEffect(() => {
    const map = new WebMap({
      portalItem: {
        id: ctxWebMapId,
      },
    });
    const view = new MapView({
      container: mapRef.current,
      map: map,
      extent: ctxGemeenteExtent,
    });

    setMapView(view);
    mapviewRef.current = view;

    if (props.mode == "update" && props.viewmode != "View") {
      ////Custom Button
      var element = document.createElement("div");
      if (props.editing) element.innerHTML = "Afsluiten Bewerken";
      else element.innerHTML = "Bewerken Plangebied";
      element.className = "custombutton";
      element.style.backgroundColor = window.Color;
      // element.style.width = "200px";
      // element.style.height = "30px";
      element.addEventListener("click", function (evt) {
        props.switch();
      });

      if (props.editing) view.ui.add(element, "top-left");
      else view.ui.add(element, "bottom-right");

      ///End Custom Button
    }

    view.when(function () {
      if (props.mode == "update") {
        //Filter op feature/geometrie van het plan
        view.map.layers.forEach((layer) => {
          if (layer.title.includes(window.FeaturelayerTitle)) {
            //als definitionexpression niet leeg is, dan eraan plakken met AND
            if (
              layer.definitionExpression != undefined &&
              layer.definitionExpression != null &&
              layer.definitionExpression != ""
            ) {
              layer.definitionExpression = layer.definitionExpression + " AND ";

              layer.definitionExpression =
                layer.definitionExpression +
                " OBJECTID = " +
                ctxSelectedPlan.attributes[window.Objectid];
            } else {
              layer.definitionExpression =
                " OBJECTID = " + ctxSelectedPlan.attributes[window.Objectid];
            }

            if (ctxSelectedPlan.geometry.extent)
              view.extent = ctxSelectedPlan.geometry.extent; //is empty with new geometry

            console.log("LayerDef: ", layer.definitionExpression);
          }
        });
      }

      if (props.editing) {
        var layerList = new LayerList({
          view: view,
        });
        //view.ui.add(layerList, "bottom-left");

        view.map.layers.forEach((layer) => {
          if (layer.title.includes(window.FeaturelayerTitle)) {
            //event koppelen op add feature
            layer.on("edits", function (event) {
              const extractObjectId = function (result) {
                return result.objectId;
              };
              const adds = event.addedFeatures.map(extractObjectId);
              console.log("addedFeatures: ", adds.length, adds);
              if (adds.length > 0) {
                props.addedplan(adds[0]);
              }
            });

            //fields which are shown in the Editor
            const editConfigLayer = {
              layer: layer,
              fieldConfig: [
                {
                  name: "plannaam",
                  label: "Plannaam",
                },
                {
                  name: "status_planologisch",
                  label: "Status planologisch",
                },
              ],
              deleteEnabled: false,
            };

            const editor = new Editor({
              view: view,
              allowedWorkflows: [props.mode], // create or update
              layerInfos: [editConfigLayer],
            });
            //view.ui.add(editor, "top-left");
            var codeExpand2 = new Expand({
              expandIconClass: "esri-icon-edit",
              expandTooltip: "Toon editor",
              view: view,
              content: editor,
              expanded: true,
              group: "groepje",
            });
            //add the panel to the UI
            view.ui.add(codeExpand2, "top-left");

            var codeExpand = new Expand({
              expandIconClass: "esri-icon-layer-list",
              expandTooltip: "Toon lijst met lagen",
              view: view,
              content: layerList,
              expanded: false,
              group: "groepje",
            });
            //add the panel to the UI
            view.ui.add(codeExpand, "top-left");
          }
        });
      }
    });

    // clean up
    return () => {
      mapview && mapview.destroy();
    };
  }, []); // only after initial render

  return <div style={{ height: "100%", width: "100%" }} ref={mapRef} />;
}

export default EditMap;
