import React, { useEffect, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EditDetailEigendom from "./EditDetailEigendom";
import { getDetailRecords } from "./GetDetailRecords";
import { AppContext } from "./AppContext";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { addPlanRelated } from "./AddPlanRelated";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function EditDetailGegevens(props) {
  const {
    ctxSelectedPlan,
    ctxDetailTableUrl,
    ctxUserSession,
    ctxDetailRecordsValid,
    ctxSetDetailRecordsValid,
  } = useContext(AppContext);

  const viewMode = useRef(props.viewmode);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [detailRecords, setDetailRecords] = React.useState(null);
  const [detailFields, setDetailFields] = React.useState(null);
  //voor te onthouden welke verticale tab geselecteerd is zodat bij wisselen Categorie, dezelfde verticale tab geselecteerd blijft
  const [verticalTabIndex, setVerticalTabIndex] = React.useState(0);

  const refPlancapaciteit = useRef(0);
  const refButton = useRef(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleVerticalTabIndex = (index) => {
    setVerticalTabIndex(index);
  };

  const getDetailInfo = () => {
    console.log("getDetailInfo");
    getDetailRecords(
      ctxSelectedPlan.attributes,
      ctxDetailTableUrl,
      ctxUserSession.token
    ).then(([fields, features]) => {
      console.log("[EditDetailGegevens]-Detailrecords opnieuw opgehaald");
      setDetailRecords(features);
      if (fields != undefined) setDetailFields([fields]);
      ctxSetDetailRecordsValid(true);
    });
  };

  const onError = () => {
    refButton.current.style.display = "inline-flex";
  };

  const onClickGenereer = () => {
    refButton.current.style.display = "none";
    addPlanRelated(
      ctxUserSession,
      ctxSelectedPlan.attributes,
      ctxDetailTableUrl,
      refPlancapaciteit.current,
      getDetailInfo,
      onError
    );
  };

  useEffect(() => {
    calcTotalen();
  }, [detailRecords]);

  useEffect(() => {
    console.log("USEEFFECT: ctxDetailTableUrl,ctxSelectedPlan");
    getDetailInfo();
  }, [ctxDetailTableUrl, ctxSelectedPlan]);

  useEffect(() => {
    console.log("USEEFFECT: ctxDetailRecordsValid");
    if (!ctxDetailRecordsValid) {
      console.log("Detailrecords not valid");
      getDetailInfo();
    }
  }, [ctxDetailRecordsValid]);

  //Optellingen
  const [optellingen, setOptellingen] = useState([]);
  useEffect(() => {
    let newOptellingen = [];
    newOptellingen["Eengezins"] = [0, 0, 0, 0];
    newOptellingen["Meergezins"] = [0, 0, 0, 0];
    newOptellingen["Onbekend"] = [0, 0, 0, 0];
    newOptellingen["Sloop-Eengezins"] = [0, 0, 0, 0];
    newOptellingen["Sloop-Meergezins"] = [0, 0, 0, 0];
    newOptellingen["Sloop-Onbekend"] = [0, 0, 0, 0];
    setOptellingen(newOptellingen);
  }, []);

  const calcTotalen = () => {
    if (detailRecords) {
      console.log("Totalen Berekening");
      let eengezins = 0;
      let eengezins_k = 0;
      let eengezins_h = 0;
      let eengezins_o = 0;
      let meergezins = 0;
      let meergezins_k = 0;
      let meergezins_h = 0;
      let meergezins_o = 0;
      let onbekend = 0;
      let onbekend_k = 0;
      let onbekend_h = 0;
      let onbekend_o = 0;
      let sloop_eengezins = 0;
      let sloop_eengezins_k = 0;
      let sloop_eengezins_h = 0;
      let sloop_eengezins_o = 0;
      let sloop_meergezins = 0;
      let sloop_meergezins_k = 0;
      let sloop_meergezins_h = 0;
      let sloop_meergezins_o = 0;
      let sloop_onbekend = 0;
      let sloop_onbekend_k = 0;
      let sloop_onbekend_h = 0;
      let sloop_onbekend_o = 0;

      let gerealiseerd_bouw = {};
      let gerealiseerd_sloop = {};
      let resterend_bouw = {};
      let resterend_sloop = {};

      detailRecords.forEach((record) => {
        console.log(record);
        //Initialiseren voor ieder jaar
        if (!(record.attributes["jaartal"] in gerealiseerd_bouw))
          gerealiseerd_bouw[record.attributes["jaartal"]] = 0;
        if (!(record.attributes["jaartal"] in gerealiseerd_sloop))
          gerealiseerd_sloop[record.attributes["jaartal"]] = 0;
        if (!(record.attributes["jaartal"] in resterend_bouw))
          resterend_bouw[record.attributes["jaartal"]] = 0;
        if (!(record.attributes["jaartal"] in resterend_sloop))
          resterend_sloop[record.attributes["jaartal"]] = 0;

        for (const [key, value] of Object.entries(record.attributes)) {
          //Eengezins
          if (key.toLowerCase().startsWith("eengezins")) {
            let parsedvalue = parseInt(value);
            if (isNaN(parsedvalue)) continue;
            eengezins += parsedvalue;

            if (record.attributes["bouw_gerealiseerd"] == "Ja")
              gerealiseerd_bouw[record.attributes["jaartal"]] += parsedvalue;
            else resterend_bouw[record.attributes["jaartal"]] += parsedvalue;
            if (key.toLowerCase().startsWith("eengezins_koop")) {
              eengezins_k += parsedvalue;
            }
            if (key.toLowerCase().startsWith("eengezins_huur")) {
              eengezins_h += parsedvalue;
            }
            if (key.toLowerCase().startsWith("eengezins_onbekend")) {
              eengezins_o += parsedvalue;
            }
          }

          //Meergezins
          if (key.toLowerCase().startsWith("meergezins")) {
            let parsedvalue = parseInt(value);
            if (isNaN(parsedvalue)) continue;
            meergezins += parsedvalue;
            if (record.attributes["bouw_gerealiseerd"] == "Ja")
              gerealiseerd_bouw[record.attributes["jaartal"]] += parsedvalue;
            else resterend_bouw[record.attributes["jaartal"]] += parsedvalue;
            if (key.toLowerCase().startsWith("meergezins_koop")) {
              meergezins_k += parsedvalue;
            }
            if (key.toLowerCase().startsWith("meergezins_huur")) {
              meergezins_h += parsedvalue;
            }
            if (key.toLowerCase().startsWith("meergezins_onbekend")) {
              meergezins_o += parsedvalue;
            }
          }

          //Onbekend
          if (key.toLowerCase().startsWith("onbekend")) {
            let parsedvalue = parseInt(value);
            if (isNaN(parsedvalue)) continue;
            onbekend += parsedvalue;
            if (record.attributes["bouw_gerealiseerd"] == "Ja")
              gerealiseerd_bouw[record.attributes["jaartal"]] += parsedvalue;
            else resterend_bouw[record.attributes["jaartal"]] += parsedvalue;
            if (key.toLowerCase().startsWith("onbekend_koop")) {
              onbekend_k += parsedvalue;
            }
            if (key.toLowerCase().startsWith("onbekend_huur")) {
              onbekend_h += parsedvalue;
            }
            if (key.toLowerCase().startsWith("onbekend_onbekend")) {
              onbekend_o += parsedvalue;
            }
          }

          //Sloop-Eengezins
          if (key.toLowerCase().startsWith("sloop_eengezins")) {
            let parsedvalue = parseInt(value);
            if (isNaN(parsedvalue)) continue;
            sloop_eengezins += parsedvalue;
            if (record.attributes["sloop_gerealiseerd"] == "Ja")
              gerealiseerd_sloop[record.attributes["jaartal"]] += parsedvalue;
            else resterend_sloop[record.attributes["jaartal"]] += parsedvalue;
            if (key.toLowerCase().startsWith("sloop_eengezins_koop")) {
              sloop_eengezins_k += parsedvalue;
            }
            if (key.toLowerCase().startsWith("sloop_eengezins_huur")) {
              sloop_eengezins_h += parsedvalue;
            }
            if (key.toLowerCase().startsWith("sloop_eengezins_onbekend")) {
              sloop_eengezins_o += parsedvalue;
            }
          }

          //Sloop-Meergezins
          if (key.toLowerCase().startsWith("sloop_meergezins")) {
            let parsedvalue = parseInt(value);
            if (isNaN(parsedvalue)) continue;
            sloop_meergezins += parsedvalue;
            if (record.attributes["sloop_gerealiseerd"] == "Ja")
              gerealiseerd_sloop[record.attributes["jaartal"]] += parsedvalue;
            else resterend_sloop[record.attributes["jaartal"]] += parsedvalue;
            if (key.toLowerCase().startsWith("sloop_meergezins_koop")) {
              sloop_meergezins_k += parsedvalue;
            }
            if (key.toLowerCase().startsWith("sloop_meergezins_huur")) {
              sloop_meergezins_h += parsedvalue;
            }
            if (key.toLowerCase().startsWith("sloop_meergezins_onbekend")) {
              sloop_meergezins_o += parsedvalue;
            }
          }

          //Sloop-Onbekend
          if (key.toLowerCase().startsWith("sloop_onbekend")) {
            let parsedvalue = parseInt(value);
            if (isNaN(parsedvalue)) continue;
            sloop_onbekend += parsedvalue;
            if (record.attributes["sloop_gerealiseerd"] == "Ja")
              gerealiseerd_sloop[record.attributes["jaartal"]] += parsedvalue;
            else resterend_sloop[record.attributes["jaartal"]] += parsedvalue;
            if (key.toLowerCase().startsWith("sloop_onbekend_koop")) {
              sloop_onbekend_k += parsedvalue;
            }
            if (key.toLowerCase().startsWith("sloop_onbekend_huur")) {
              sloop_onbekend_h += parsedvalue;
            }
            if (key.toLowerCase().startsWith("sloop_onbekend_onbekend")) {
              sloop_onbekend_o += parsedvalue;
            }
          }
        }
      });

      let newOptellingen = [];
      newOptellingen["Eengezins"] = [
        eengezins,
        eengezins_k,
        eengezins_h,
        eengezins_o,
      ];
      newOptellingen["Meergezins"] = [
        meergezins,
        meergezins_k,
        meergezins_h,
        meergezins_o,
      ];
      newOptellingen["Onbekend"] = [
        onbekend,
        onbekend_k,
        onbekend_h,
        onbekend_o,
      ];
      newOptellingen["Sloop-Eengezins"] = [
        sloop_eengezins,
        sloop_eengezins_k,
        sloop_eengezins_h,
        sloop_eengezins_o,
      ];
      newOptellingen["Sloop-Meergezins"] = [
        sloop_meergezins,
        sloop_meergezins_k,
        sloop_meergezins_h,
        sloop_meergezins_o,
      ];
      newOptellingen["Sloop-Onbekend"] = [
        sloop_onbekend,
        sloop_onbekend_k,
        sloop_onbekend_h,
        sloop_onbekend_o,
      ];

      console.log("Optellingen:", newOptellingen);
      console.log("GerealiseerdBouw: ", gerealiseerd_bouw);
      console.log("GerealiseerdSloop: ", gerealiseerd_sloop);
      console.log("ResterendBouw: ", resterend_bouw);
      console.log("ResterendSloop: ", resterend_sloop);
      setOptellingen(newOptellingen);
      props.settotaleninfo([
        gerealiseerd_bouw,
        gerealiseerd_sloop,
        resterend_bouw,
        resterend_sloop,
      ]);
    }
  };

  return (
    <div>
      {detailRecords === undefined ||
      detailRecords === null ||
      detailRecords.length === 0 ? (
        <div>
          {detailRecords === undefined ? (
            <div>Planning ophalen...</div>
          ) : (
            <div>
              <div style={{ margin: "35px" }}>
                <div style={{ display: "inline" }}>
                  <span
                    style={{
                      textAlign: "left",
                      margin: "0 auto",
                      width: "60%",
                      marginTop: "30px",
                    }}
                  >
                    De detailplanning kan aangemaakt worden door allereerst
                    hierboven de jaartallen voor 'Eerste oplevering' en 'Laatste
                    oplevering' in te vullen.
                    <br />
                    <br />
                    Vul vervolgens hieronder het aantal woningen in bij
                    'Plancapaciteit'.
                  </span>
                </div>
                <br />
                <TextField
                  onChange={(e) => {
                    refPlancapaciteit.current = e.target.value;
                  }}
                  style={{ margin: "5px" }}
                  size="small"
                  id="Plancapaciteit"
                  label="Plancapaciteit"
                  variant="outlined"
                  autoComplete="off"
                  disabled={viewMode.current == "View"}
                />
                <Button
                  disabled={viewMode.current == "View"}
                  ref={refButton}
                  id="genereer"
                  variant="outlined"
                  style={{ margin: "5px" }}
                  onClick={() => {
                    onClickGenereer();
                  }}
                >
                  Genereer
                </Button>
                <br />
                <div style={{ width: "60%", display: "inline" }}>
                  <span
                    style={{
                      textAlign: "left",
                      margin: "0 auto",
                      width: "60%",
                      marginTop: "30px",
                    }}
                  >
                    Klik op de knop GENEREER om een detailplanning over de jaren
                    aan te maken. <br />
                    <br />
                    <b>LET OP</b> <br />
                    Bij het aanmaken wordt het aantal woningen automatisch
                    verdeeld over de jaren met woningtype en prijsklasse
                    'onbekend'. Deze verdeling kan vervolgens aangepast worden
                    waarbij het totaal aantal woningen rechtsonder wordt
                    weergegeven; klik in het volgende scherm op de bereken-knop
                    (
                    <img
                      style={{ width: "30px", verticalAlign: "middle" }}
                      src="./calculator.png"
                      alt="image"
                    />
                    ) om dit totaal telkens bij te werken.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.root}>
          <AppBar position="static" style={{ backgroundColor: window.Color }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
            >
              <Tab
                label={`Eengezins(${optellingen["Eengezins"][0]})`}
                {...a11yProps(0)}
              />
              <Tab
                label={`Meergezins(${optellingen["Meergezins"][0]})`}
                {...a11yProps(1)}
              />
              <Tab
                label={`Onbekend(${optellingen["Onbekend"][0]})`}
                {...a11yProps(2)}
              />
              <Tab
                label={`Sloop-Eengezins(${optellingen["Sloop-Eengezins"][0]})`}
                {...a11yProps(3)}
              />
              <Tab
                label={`Sloop-Meergezins(${optellingen["Sloop-Meergezins"][0]})`}
                {...a11yProps(4)}
              />
              <Tab
                label={`Sloop-Onbekend(${optellingen["Sloop-Onbekend"][0]})`}
                {...a11yProps(5)}
              />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <EditDetailEigendom
              viewmode={viewMode.current}
              detailrecords={detailRecords}
              detailfields={detailFields}
              categorie="Eengezins"
              verticaltabindex={verticalTabIndex}
              verticaltabindexchange={handleVerticalTabIndex}
              optellingen={optellingen["Eengezins"]}
            ></EditDetailEigendom>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EditDetailEigendom
              viewmode={viewMode.current}
              detailrecords={detailRecords}
              detailfields={detailFields}
              categorie="Meergezins"
              verticaltabindex={verticalTabIndex}
              verticaltabindexchange={handleVerticalTabIndex}
              optellingen={optellingen["Meergezins"]}
            ></EditDetailEigendom>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EditDetailEigendom
              viewmode={viewMode.current}
              detailrecords={detailRecords}
              detailfields={detailFields}
              categorie="Onbekend"
              verticaltabindex={verticalTabIndex}
              verticaltabindexchange={handleVerticalTabIndex}
              optellingen={optellingen["Onbekend"]}
            ></EditDetailEigendom>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <EditDetailEigendom
              viewmode={viewMode.current}
              detailrecords={detailRecords}
              detailfields={detailFields}
              categorie="Sloop-Eengezins"
              verticaltabindex={verticalTabIndex}
              verticaltabindexchange={handleVerticalTabIndex}
              optellingen={optellingen["Sloop-Eengezins"]}
            ></EditDetailEigendom>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <EditDetailEigendom
              viewmode={viewMode.current}
              detailrecords={detailRecords}
              detailfields={detailFields}
              categorie="Sloop-Meergezins"
              verticaltabindex={verticalTabIndex}
              verticaltabindexchange={handleVerticalTabIndex}
              optellingen={optellingen["Sloop-Meergezins"]}
            ></EditDetailEigendom>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <EditDetailEigendom
              viewmode={viewMode.current}
              detailrecords={detailRecords}
              detailfields={detailFields}
              categorie="Sloop-Onbekend"
              verticaltabindex={verticalTabIndex}
              verticaltabindexchange={handleVerticalTabIndex}
              optellingen={optellingen["Sloop-Onbekend"]}
            ></EditDetailEigendom>
          </TabPanel>
        </div>
      )}
    </div>
  );
}
