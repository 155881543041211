import React, { useContext, useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from "./AppContext";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ApplicationHeader(props) {
  const classes = useStyles();

  const { ctxCredentials, ctxGemeentenaam, ctxSettings } =
    useContext(AppContext);

  const [handleidingurl, setHandleidingurl] = useState("");

  useEffect(() => {}, [ctxGemeentenaam]);
  useEffect(() => {
    if (
      ctxSettings["handleiding"] != "" &&
      ctxSettings["handleiding"] != null &&
      ctxSettings["handleiding"] != undefined
    )
      setHandleidingurl(ctxSettings["handleiding"]);
    console.log("Handleiding: ", ctxSettings["handleiding"]);
  }, [ctxSettings]);

  const onClickHandleiding = () => {
    window.open(handleidingurl);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: window.Color }}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={props.buttonexitclicked}
          style={{
            visibility:
              props.viewmode === "Modify" || props.viewmode === "View"
                ? "visible"
                : "hidden",
          }}
        >
          <img
            style={{ cursor: "pointer", width: "30px" }}
            src={
              props.viewmode === "Modify" ? "./saveandhome.png" : "./home.png"
            }
            alt="image"
          />
        </IconButton>
        <Typography variant="button">Gemeente {ctxGemeentenaam}</Typography>

        <Typography variant="h6" className={classes.title}>
          {props.title}
        </Typography>
        <Typography variant="button">
          {ctxCredentials != undefined ? (
            <div>
              {ctxCredentials.userId}{" "}
              <Button
                color="inherit"
                onClick={props.buttonlogoutclicked}
                disabled={props.viewmode !== "Main"}
              >
                Logout
              </Button>
            </div>
          ) : (
            "Not logged in"
          )}
        </Typography>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={onClickHandleiding}
          disabled={handleidingurl === ""}
        >
          <HelpOutlineIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
