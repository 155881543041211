import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import JSONDetailvelden from "./config/config_detail.json";
import "./App.css";
import { AppContext } from "./AppContext";
import { updateFeatureAttr } from "./UpdateFeatureAttr";
import { NotifyFailure } from "./Notify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

//Categorie kan zijn: Eengezins, Meergezins, Onbekend, Sloop-Eengezins, Sloop-Meergezins, Sloop-Onbekend
//Binnen iedere categorie zijn er 3 types: Koop, Huur, Onbekend
//EditDetailEigendom is op het niveau Categorie-Type. Dus bv Meergezins-Koop
//JSONDetailVelden is een array met objecten waarin alle velden per Categorie en Type opgenomen zijn
//Omdat we de Categorie en het Type weten, krijgen we een array met velden die getoond moeten worden
//In geval van Meergezins-Koop zou dat deze 5 velden zijn: "meergezins_koop1", "meergezins_koop2", ... , "meergezins_koop_onb"
//De detailplanning van een plan kan 0, 1 of meer jaren bevatten. Voor ieder jaar tonen we de velden met de waarde

export default function EditDetailEigendom(props) {
  const classes = useStyles();
  const [valueTabIndex, setValueTabIndex] = useState(props.verticaltabindex);
  const { detailrecords, categorie, detailfields, optellingen } = props;
  const refOnFocus = useRef([]);
  const refObjIDOnFocus = useRef(0);

  const viewMode = useRef(props.viewmode);

  const { ctxUserSession, ctxDetailTableUrl, ctxSetDetailRecordsValid } =
    useContext(AppContext);

  useEffect(() => {
    console.log("USEEFFECT: categorie");
  }, [categorie, detailrecords]);

  const handleChange = (event, newValue) => {
    setValueTabIndex(newValue);
    props.verticaltabindexchange(newValue);
  };

  const callbackUpdated = (objid, fieldname, value) => {
    console.log("Callback updateFeatureAttr: ", objid);
    for (let index = 0; index < detailrecords.length; index++) {
      //zoek de juiste row op
      if (detailrecords[index].attributes[window.Objectid] == objid) {
        //juiste row gevonden: pas nu het gewijzigde veld aan
        detailrecords[index].attributes[fieldname] = value;
        break;
      }
    }
  };

  const onLeave = (evt) => {
    if (
      evt.target.id == refOnFocus.current[0] &&
      evt.target.value != refOnFocus.current[1]
    ) {
      if (evt.target.value != "" && isNaN(parseInt(evt.target.value))) {
        NotifyFailure("", "Ongeldig getal ingevoerd");
        evt.target.value = refOnFocus.current[1];
        return;
      }

      console.log(
        "OBJECTID:" +
          refObjIDOnFocus.current +
          " Value changed: " +
          evt.target.id +
          " Value: " +
          evt.target.value
      );
      if (evt.target.id.toLowerCase().includes("sloop")) {
        if (evt.target.value > 0) {
          evt.target.value = evt.target.value * -1;
        }
      }
      if (viewMode.current == "View") {
        alert("Viewmode");
        return;
      }
      updateFeatureAttr(
        ctxDetailTableUrl,
        refObjIDOnFocus.current,
        evt.target.id,
        evt.target.value,
        ctxUserSession,
        callbackUpdated
      );
    }
  };

  const onFocus = (evt) => {
    refOnFocus.current = [evt.target.id, evt.target.value];
    console.log(evt.target.parentNode.parentNode.id);
    //Hier als extra het objectid van de huidige row ophalen en bewaren
    //Die gebruiken we later om een evt. update te doen
    refObjIDOnFocus.current = evt.target.parentNode.parentNode.id;
  };

  const onChangeDropdown = (evt) => {
    debugger;
    if (viewMode.current == "View") {
      alert("Viewmode");
      return;
    }
    console.log(evt.target.parentNode.parentNode.id);
    refObjIDOnFocus.current = evt.target.parentNode.parentNode.id;
    updateFeatureAttr(
      ctxDetailTableUrl,
      refObjIDOnFocus.current,
      evt.target.id,
      evt.target.value,
      ctxUserSession,
      callbackUpdated
    );
  };

  const TekstVeld = (props) => {
    var editable = true;
    if (viewMode.current == "View") editable = false;
    return (
      <input
        autoComplete="off"
        style={{ border: "0", width: "100%" }}
        type="text"
        name={props.field}
        id={props.field}
        defaultValue={props.waarde}
        onFocus={onFocus}
        onBlur={onLeave}
        disabled={!editable}
      />
    );
  };

  const CustomDropdown = (props) => {
    var editable = true;
    if (viewMode.current == "View") editable = false;
    return (
      <select
        id={props.field}
        disabled={!editable}
        onChange={onChangeDropdown}
        style={{ border: "none" }}
      >
        <option value="Nee" selected={props.waarde == "Nee"}>
          Nee
        </option>
        <option value="Ja" selected={props.waarde == "Ja"}>
          Ja
        </option>
      </select>
    );
  };

  const TableRows = (props) => {
    return detailrecords.map((jaarrecord) => {
      return (
        <tr id={jaarrecord.attributes[window.Objectid]}>
          <td
            key={"unieka"}
            style={{
              textAlign: "left",
              // color: "white",
            }}
          >
            {jaarrecord.attributes["jaartal"]}
          </td>
          <td
            key={"uniekb"}
            style={{
              textAlign: "left",
              // color: "white",
            }}
          >
            {categorie.includes("Sloop") ? (
              <CustomDropdown
                field={"sloop_gerealiseerd"}
                waarde={jaarrecord.attributes["sloop_gerealiseerd"]}
              />
            ) : (
              <CustomDropdown
                field={"bouw_gerealiseerd"}
                waarde={jaarrecord.attributes["bouw_gerealiseerd"]}
              />
            )}
          </td>
          {JSONDetailvelden[0][categorie][props.type].map((veld, index) => {
            const alias = getFieldAlias(veld);
            if(alias != null)
            {
            return (
              <td key={index}>
                <TekstVeld waarde={jaarrecord.attributes[veld]} field={veld} />
              </td>
            );}
            else
            {
              return
            }
          })}
        </tr>
      );
    });
  };

  function getFieldAlias(fieldname) {
    if (detailfields === null ||
        detailfields === undefined ||
        detailfields.length === 0)
    {
      return fieldname;
    }
    
    for (var i = 0; i < detailfields[0].length; i++) 
    {
      if (detailfields[0][i].name == fieldname)
      {
         return detailfields[0][i].alias;
      }
    }

    // Retrun null, no alias found, this means the field isn't available in the view. 
    return null;
  }

  const TableHeader = (props) => {
    return (
      <tr>
        <th
          key="uniek1"
          style={{
            textAlign: "left",
            wordBreak: "break-all",
            backgroundColor: window.Color,
          }}
        >
          Jaar
        </th>
        <th
          key="uniek2"
          style={{
            textAlign: "left",
            wordBreak: "break-all",
            width: "100px",
            backgroundColor: window.Color,
          }}
        >
          Gerealiseerd
        </th>
        {JSONDetailvelden[0][categorie][props.type].map((veld, index) => {
          
          const alias = getFieldAlias(veld);
          if(alias != null)
          {
          return (
            <th
              key={index}
              style={{
                textAlign: "left",
                wordBreak: "break-all",
                backgroundColor: window.Color,
                width: alias == null ? '0px' : 'auto'
              }}
            >
              {alias}
            </th>
          );}
          else
          {
            return
          }
        })}
      </tr>
    );
  };

  const reCalculate = () => {
    ctxSetDetailRecordsValid(false);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="fullWidth" //"fullWidth","scrollable","standard"
        value={valueTabIndex}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          style={{
            background: valueTabIndex == 0 ? window.Color : "",
            color: valueTabIndex == 0 ? "white" : "",
          }}
          label={`Koop(${optellingen[1]})`}
          {...a11yProps(0)}
        />
        <Tab
          style={{
            background: valueTabIndex == 1 ? window.Color : "",
            color: valueTabIndex == 1 ? "white" : "",
          }}
          label={`Huur(${optellingen[2]})`}
          {...a11yProps(1)}
        />
        <Tab
          wrapped
          style={{
            background: valueTabIndex == 2 ? window.Color : "",
            color: valueTabIndex == 2 ? "white" : "",
          }}
          label={`Eigendom onbekend(${optellingen[3]})`}
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel
        value={valueTabIndex}
        index={0}
        style={{ width: "100%", height: "100%", overflow: "auto" }}
      >
        <div>
          <div style={{ float: "left", display: "inline-flex" }}>
            <table id="tabledetailplanning">
              <TableHeader type={"koop"}></TableHeader>
              <tbody>
                <TableRows type={"koop"}></TableRows>
              </tbody>
            </table>{" "}
            <div style={{ marginLeft: "20px", float: "left" }}>
              <img
                style={{ cursor: "pointer" }}
                src="./calculator.png"
                alt="image"
                onClick={() => reCalculate()}
              />
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel
        value={valueTabIndex}
        index={1}
        style={{ width: "100%", height: "100%", overflow: "auto" }}
      >
        <div>
          <div style={{ float: "left", display: "inline-flex" }}>
            <table id="tabledetailplanning">
              <TableHeader type={"huur"}></TableHeader>
              <tbody>
                <TableRows type={"huur"}></TableRows>
              </tbody>
            </table>{" "}
            <div style={{ marginLeft: "20px", float: "left" }}>
              <img
                style={{ cursor: "pointer" }}
                src="./calculator.png"
                alt="image"
                onClick={() => reCalculate()}
              />
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel
        value={valueTabIndex}
        index={2}
        style={{ width: "100%", height: "100%", overflow: "auto" }}
      >
        <div>
          <div style={{ float: "left", display: "inline-flex" }}>
            <table id="tabledetailplanning">
              <TableHeader type={"onbekend"}></TableHeader>
              <tbody>
                <TableRows type={"onbekend"}></TableRows>
              </tbody>
            </table>{" "}
            <div style={{ marginLeft: "20px", float: "left" }}>
              <img
                style={{ cursor: "pointer" }}
                src="./calculator.png"
                alt="image"
                onClick={() => reCalculate()}
              />
            </div>
          </div>
        </div>
      </TabPanel>
    </div>
  );
}
