import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import "./App.css";
import planColumns from "./config/config_plan.json";
import { AppContext } from "./AppContext";
import { NewTable } from "./NewTable";

//Alternatief: https://codesandbox.io/s/23jy4py5yr?file=/Utils.js

export default function MainTable(props) {
  const {
    ctxPlannen,
    ctxSetPlannen,
    ctxSetSelectedPlan,
    ctxFeaturelayer,
    ctxUserSession,
    ctxTableFilter,
    ctxPlannenFields,
    ctxSetPlannenFields,
  } = useContext(AppContext);

  const [kolommen, setKolommen] = useState([]);
  const [rijen, setRijen] = useState([]);

  //Recursive
  const HaalAllePlannen = (allFeatures, callback) => {
    var query = ctxFeaturelayer.createQuery();
    query.start = allFeatures.length;
    query.num = 1000;
    ctxFeaturelayer.queryFeatures(query).then(function (response) {
      if (allFeatures.length == 0) {
        ctxSetPlannenFields(response.fields);
      }
      if (response.exceededTransferLimit) {
        allFeatures = allFeatures.concat(response.features);
        HaalAllePlannen(allFeatures, callback);
      } else {
        allFeatures = allFeatures.concat(response.features);
        callback(allFeatures);
      }
    });
  };

  const OphalenPlannenGereed = (allFeatures) => {
    if (allFeatures.length > 0) {
      var tmprows = [];
      allFeatures.forEach((plan) => {
        var rij = {};
        //er moet verplicht een id-kolom zijn. Deze vullen we met OBJECTID en tonen we niet
        rij["id"] = plan.attributes[window.Objectid];
        planColumns.forEach((column) => {
          rij[column.field] = plan.attributes[column.field];
        });
        tmprows.push(rij);
      });
      setRijen(tmprows);
    }
    ctxSetPlannen(allFeatures);
    console.log("GETPLANNEN: ", allFeatures.length);
  };

  //featurelayer-state is gewijzigd: door het laden van de map(view)
  //Plannen uit de featurelayer lezen en de rijen van de tabel vullen
  useEffect(() => {
    if (ctxFeaturelayer != null && ctxUserSession != null) {
      //featurelayer is gewijzigd: ophalen plannen en in tabel zetten
      let allFeatures = [];
      //Deze functie is recursive met daarbinnen async calls. Wanneer ie helemaal gereed is, roept ie 'OphalenPlannenGereed' aan
      HaalAllePlannen(allFeatures, OphalenPlannenGereed);
    }
  }, [ctxFeaturelayer, props.dataValid]);

  //filter van de tabel is gewijzigd (omdat een feature op de map is aangeklikt bv)
  useEffect(() => {}, [ctxTableFilter]);

  //de attribuutvelden van de plannen uitlezen, bewaren en kolommen van tabel maken
  useEffect(() => {
    var tmpkolommen = [];
    if (ctxPlannenFields != null && ctxPlannenFields.length > 0) {
      //er moet verplicht een id-kolom zijn. Deze vullen we met OBJECTID en tonen we niet
      var tmpcolumns = [];
      var newcolumn = {};
      newcolumn["Header"] = "id";
      newcolumn["accessor"] = "id";
      newcolumn["defaultCanSort"] = true;
      newcolumn["width"] = 50;
      tmpcolumns.push(newcolumn);
      planColumns.forEach((column) => {
        newcolumn = {};
        newcolumn["Header"] = getAlias(column.field);
        newcolumn["accessor"] = column.field;
        newcolumn["defaultCanSort"] = true;
        tmpcolumns.push(newcolumn);
      });
      setKolommen(tmpcolumns);
      console.log("Nieuwe Columns: " + tmpcolumns);
    }
  }, [ctxPlannenFields]);

  function getAlias(fieldname) {
    for (var i = 0; i < ctxPlannenFields.length; i++) {
      if (ctxPlannenFields[i].name == fieldname)
        return ctxPlannenFields[i].alias;
    }
    return fieldname;
  }

  return (
    <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
      <div style={{ height: "85%", width: "100%", overflowX: "auto" }}>
        <NewTable rows={rijen} columns={kolommen}></NewTable>
      </div>
      <br />
      <Button
        id="add"
        variant="outlined"
        style={{
          margin: "5px",
          backgroundColor: window.Color,
          color: "white",
        }}
        onClick={props.buttontableclicked()}
      >
        Toevoegen
      </Button>
      <Button
        id="view"
        variant="outlined"
        style={{
          margin: "5px",
          backgroundColor: window.Color,
          color: "white",
        }}
        onClick={props.buttontableclicked()}
      >
        Bekijken
      </Button>
      <Button
        id="modify"
        variant="outlined"
        style={{
          margin: "5px",
          backgroundColor: window.Color,
          color: "white",
        }}
        onClick={props.buttontableclicked()}
      >
        Wijzigen
      </Button>
      <Button
        id="delete"
        variant="outlined"
        style={{
          margin: "5px",
          backgroundColor: window.Color,
          color: "white",
        }}
        onClick={props.buttontableclicked()}
      >
        Verwijderen
      </Button>
    </div>
  );
}
