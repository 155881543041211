import React, { useState } from "react";
import EditPlanGegevens from "./EditPlanGegevens";
import EditDetailGegevens from "./EditDetailGegevens";
import EditMap from "./EditMap";
import "./App.css";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";

const Edit = (props) => {
  const [editMode, setEditMode] = useState("Default");
  const [infoTotaal, setInfoTotaal] = useState([]);

  const TotalenInfo = () => {
    if (infoTotaal.length == 0) return <div></div>;
    //infoTotaal is een array van 4 stuks:  gerealiseerd_bouw, gerealiseerd_sloop, resterend_bouw, resterend_sloop
    //ieder onderdeel bevat een array van jaren met daarin aantallen
    //dus bv gerealiseerd_bouw={2021: 64, 2022: 0, 2023: 0}
    //en bv resterend_bouw={2021: 0, 2022: 35, 2023: 35}

    //We willen:
    //-alle jaren gerealiseerd_bouw opgeteld hebben
    //-alle jaren gerealiseerd_sloop opgeteld hebben
    //-alle jaren resterend_bouw opgeteld hebben
    //-alle jaren resterend_sloop opgeteld hebben
    let gerealiseerd_bouw = 0;
    let gerealiseerd_sloop = 0;
    let resterend_bouw = 0;
    let resterend_sloop = 0;
    Object.values(infoTotaal[0]).forEach((jaarvalue) => {
      gerealiseerd_bouw += jaarvalue;
    });
    Object.values(infoTotaal[1]).forEach((jaarvalue) => {
      gerealiseerd_sloop += jaarvalue;
    });
    Object.values(infoTotaal[2]).forEach((jaarvalue) => {
      resterend_bouw += jaarvalue;
    });
    Object.values(infoTotaal[3]).forEach((jaarvalue) => {
      resterend_sloop += jaarvalue;
    });
    return (
      <table>
        <tr>
          <th style={{ width: "100px" }}></th>
          <th style={{ width: "100px" }}>Bouw</th>
          <th style={{ width: "100px" }}>Sloop</th>
        </tr>
        <tr>
          <td>Gerealiseerd</td>
          <td>{gerealiseerd_bouw}</td>
          <td>{gerealiseerd_sloop}</td>
        </tr>
        <tr>
          <td>Resterend</td>
          <td>{resterend_bouw}</td>
          <td>{resterend_sloop}</td>
        </tr>
        <tr>
          <td>Totaal</td>
          <td>{gerealiseerd_bouw + resterend_bouw}</td>
          <td>{gerealiseerd_sloop + resterend_sloop}</td>
        </tr>
      </table>
    );
  };

  const TotalenInfoJaren = () => {
    if (infoTotaal.length == 0) return <div></div>;
    //infoTotaal is een array van 4 stuks:  gerealiseerd_bouw, gerealiseerd_sloop, resterend_bouw, resterend_sloop
    //ieder onderdeel bevat een array van jaren met daarin aantallen
    //dus bv gerealiseerd_bouw={2021: 64, 2022: 0, 2023: 0}

    //eerst bepalen welke jaren er zijn
    let jaren = Object.keys(infoTotaal[0]);
    //dan loop over de jaren
    return jaren.map((jaar, index) => {
      var gerealiseerdbouw = parseInt(infoTotaal[0][jaar]);
      var resterendbouw = parseInt(infoTotaal[2][jaar]);
      var gerealiseerdsloop = parseInt(infoTotaal[1][jaar]);
      var resterendsloop = parseInt(infoTotaal[3][jaar]);
      return (
        <tr key={index}>
          <td key={jaar}>{jaar}</td>
          <td key={0}>{gerealiseerdbouw + resterendbouw}</td>
          <td key={1}>{gerealiseerdsloop + resterendsloop}</td>
        </tr>
      );
    });
  };

  //deze wordt aangeroepen vanuit EditDetailgegevens, nadat de totalen berekend zijn
  const setTotalenInfo = (props) => {
    console.log("SetTotalenInfo: ", props);
    setInfoTotaal(props);
  };

  return (
    <div>
      {editMode == "Default" ? (
        <div>
          <div className="EditdivTop">
            <div
              className="Editdivchild"
              style={{ width: "70%", overflow: "auto" }}
            >
              <EditPlanGegevens
                viewmode={props.viewmode}
                totalen={infoTotaal}
              ></EditPlanGegevens>
            </div>
            <div className="Editdivchild" style={{ width: "30%" }}>
              <EditMap
                viewmode={props.viewmode}
                editing={false}
                mode="update"
                switch={() => setEditMode("FullScreenEdit")}
              ></EditMap>
            </div>
          </div>
          <br></br>
          <div className="EditdivBottom">
            <div className="Editdivchild" style={{ width: "70%" }}>
              <EditDetailGegevens
                viewmode={props.viewmode}
                settotaleninfo={setTotalenInfo}
              ></EditDetailGegevens>
            </div>
            <div
              className="Editdivchild"
              style={{ width: "30%", overflow: "auto" }}
            >
              <AppBar
                position="static"
                style={{ minHeight: "48px", backgroundColor: window.Color }}
              >
                <Typography variant="button" style={{ margin: "10px" }}>
                  {"Totalen"}
                </Typography>
              </AppBar>
              <div
                style={{
                  width: "50%",
                  margin: "0 auto",
                }}
              >
                <TotalenInfo></TotalenInfo>
                <br></br>
                <table>
                  <tr>
                    <th style={{ width: "100px" }}>Jaar</th>
                    <th style={{ width: "100px" }}>Bouw</th>
                    <th style={{ width: "100px" }}>Sloop</th>
                  </tr>
                  <TotalenInfoJaren></TotalenInfoJaren>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="NewdivTop">
            <div
              className="Editdivchild"
              style={{ height: "98%", width: "100%" }}
            >
              <EditMap
                viewmode={props.viewmode}
                editing={true}
                mode="update"
                switch={() => setEditMode("Default")}
              ></EditMap>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Edit;
