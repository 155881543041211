import React, { useRef, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import JSONEditPlan from "./config/config_editplan.json";
import { AppContext } from "./AppContext";
import { updateFeatureAttr } from "./UpdateFeatureAttr";
import { NotifyFailure } from "./Notify";
import {
  addRemoveDetailRecords,
  shiftDetailRecords,
  UpdateVertrouwelijkheidDetailRecords,
} from "./UpdateDetailRecords";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ModalDialog from "./ModalDialog";
import Link from "@material-ui/core/Link";
import MessageBox from "./MessageBox";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function EditPlanGegevens(props) {
  const {
    ctxSelectedPlan,
    ctxSetSelectedPlan,
    ctxFeaturelayerUrl,
    ctxDetailTableUrl,
    ctxUserSession,
    ctxPlannenFields,
    ctxSetDetailRecordsValid,
    ctxTooltips,
    ctxGemeentenaam,
    ctxPlaatsnamen,
    ctxSettings,
  } = useContext(AppContext);

  const viewMode = useRef(props.viewmode);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [infoTotaal, setInfoTotaal] = useState(props.totalen);
  const refOnFocus = useRef([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openMessageBox, setOpenMessageBox] = React.useState(false);
  const [openMessageBoxTekst, setOpenMessageBoxTekst] = React.useState("");

  useEffect(() => {
    console.log("EditPlanGegevens: infoTotaal gewijzigd ", infoTotaal);
  }, [infoTotaal]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onLeave = (evt) => {
    if (
      evt.target.id == refOnFocus.current[0] &&
      evt.target.value != refOnFocus.current[1]
    ) {
      console.log(
        "Value changed: " + evt.target.id + " Value: " + evt.target.value
      );

      //Controles
      if (evt.target.id == "oplevering_eerste") {
        if (
          isNaN(parseInt(evt.target.value)) ||
          evt.target.value < 1950 ||
          evt.target.value > 2100
        ) {
          NotifyFailure("", "Ongeldig jaartal ingevoerd");
          evt.target.value = refOnFocus.current[1];
          return;
        }
        if (
          evt.target.value > ctxSelectedPlan.attributes["oplevering_laatste"] &&
          ctxSelectedPlan.attributes["oplevering_laatste"] != undefined &&
          ctxSelectedPlan.attributes["oplevering_laatste"] != ""
        ) {
          NotifyFailure(
            "",
            "Jaartal laatste moet groter of gelijk jaartal eerste zijn."
          );
          evt.target.value = refOnFocus.current[1];
          return;
        }
      }
      if (evt.target.id == "oplevering_laatste") {
        if (
          isNaN(parseInt(evt.target.value)) ||
          evt.target.value < 1950 ||
          evt.target.value > 2100
        ) {
          NotifyFailure("", "Ongeldig jaartal ingevoerd");
          evt.target.value = refOnFocus.current[1];
          return;
        }
        if (
          ctxSelectedPlan.attributes["oplevering_eerste"] > evt.target.value &&
          ctxSelectedPlan.attributes["oplevering_eerste"] != undefined &&
          ctxSelectedPlan.attributes["oplevering_eerste"] != ""
        ) {
          NotifyFailure(
            "",
            "Jaartal laatste moet groter of gelijk jaartal eerste zijn."
          );
          evt.target.value = refOnFocus.current[1];
          return;
        }
      }

      if (viewMode.current == "View") {
        alert("Viewmode");
        return;
      }

      //Update de waarde in de database
      updateFeatureAttr(
        ctxFeaturelayerUrl,
        ctxSelectedPlan.attributes[window.Objectid],
        evt.target.id,
        evt.target.value,
        ctxUserSession
      );
      ctxSelectedPlan.attributes[evt.target.id] = evt.target.value;
      ctxSetSelectedPlan(ctxSelectedPlan);

      //Controleren of de looptijd van het plan veranderd is
      if (
        evt.target.id == "oplevering_eerste" ||
        evt.target.id == "oplevering_laatste"
      ) {
        //in dit geval veranderd dus de looptijd van het plan.
        //Er moeten jaren bij, of jaren af
        addRemoveDetailRecords(
          ctxSelectedPlan.attributes,
          ctxDetailTableUrl,
          ctxUserSession,
          ctxSelectedPlan.attributes["oplevering_eerste"],
          ctxSelectedPlan.attributes["oplevering_laatste"],
          callbackSetInvalid
        );
      } //else ctxSetDetailRecordsValid(false);
    }
  };

  //om te zorgen dat de detailrecords vernieuwd worden omdat oplevering_eerste of laatste veranderd is
  const callbackSetInvalid = () => {
    console.log("Callback ctxSetDetailRecordsValid");
    ctxSetDetailRecordsValid(false);
  };

  const onFocus = (evt) => {
    refOnFocus.current = [evt.target.id, evt.target.value];
  };

  const handleChangeSelect = (evt) => {
    if (viewMode.current == "View") {
      alert("Viewmode");
      return;
    }
    console.log(
      "Value changed: " + evt.target.id + " Value: " + evt.target.value
    );
    updateFeatureAttr(
      ctxFeaturelayerUrl,
      ctxSelectedPlan.attributes[window.Objectid],
      evt.target.id,
      evt.target.value,
      ctxUserSession
    );
    ctxSelectedPlan.attributes[evt.target.id] = evt.target.value;
    ctxSetSelectedPlan(ctxSelectedPlan);

    //indien de vertrouwelijkeheid veranderd, dan moet ook de vertrouwelijkheid van de detailrecords aangepast worden
    if (evt.target.id == "vertrouwelijkheid") {
      UpdateVertrouwelijkheidDetailRecords(
        ctxSelectedPlan.attributes,
        ctxDetailTableUrl,
        ctxUserSession
      );
    }
  };

  function getField(fieldname) {
    for (var i = 0; i < ctxPlannenFields.length; i++) {
      if (ctxPlannenFields[i].name == fieldname) return ctxPlannenFields[i];
    }
    return null;
  }

  const Domain = (props) => {
    var domein = props.domain.slice();

    //Als het veld 'plaatsnaam' is, dan krijgt de dropdown een andere vulling
    //Deze vulling is opgehaald op basis van de Gemeentenaam
    if (props.id == "plaatsnaam") {
      console.log(ctxPlaatsnamen);
      if (ctxPlaatsnamen == null) domein = [];
      else domein = [...ctxPlaatsnamen];
    }

    //lege waarde aan de array toekennen zodat die gekozen kan worden als de value==""
    let cv = {};
    cv["code"] = "";
    cv["name"] = "";
    domein.push(cv);

    let value = props.value == undefined ? "" : props.value;
    return domein.map((codedValue) => {
      return (
        <option
          key={codedValue.code}
          value={codedValue.code}
          selected={codedValue.code == value}
        >
          {codedValue.name}
        </option>
      );
    });
  };
  const DropDown = (props) => {
    var editable = props.editable;
    if (viewMode.current == "View") {
      editable = false;
    }
    return (
      <div style={{ float: "left", clear: "none", display: "inline-flex" }}>
        <label
          style={{
            display: "inline-block",
            width: "150px",
            textAlign: "left",
            fontSize: "14px",
          }}
        >
          {props.alias}
          <div class="tooltip">
            {props.tooltip != undefined && props.tooltip != "" ? (
              <HelpOutlineIcon
                onMouseEnter={(e) =>
                  tooltipEnter(e, props.tooltip, props.field)
                }
                onMouseLeave={(e) => tooltipLeave(e, props.field)}
                fontSize="small"
                color="primary"
                style={{ fontSize: "0.75rem" }}
              ></HelpOutlineIcon>
            ) : (
              <div></div>
            )}
          </div>
        </label>
        <select
          onChange={handleChangeSelect}
          id={props.id}
          disabled={!editable}
          style={{
            display: "inline-block",
            width: "225px",
            padding: "10px",
            textAlign: "left",
            margin: "5px",
          }}
        >
          <Domain
            id={props.id}
            //value={props.value?.replace(", namelijk..", "")}
            value={props.value}
            domain={props.domain}
          ></Domain>
        </select>
      </div>
    );
  };

  function tooltipLeave(e, veldnaam) {
    if (document.getElementById("tooltip_" + veldnaam)) {
      var div = document.getElementById("tooltip_" + veldnaam);
      div.parentNode.removeChild(div);
    }
    return false;
  }
  function tooltipEnter(e, tekst, veldnaam) {
    var div = document.createElement("div");
    div.className = "customtooltipdiv";
    div.style.backgroundColor = window.Color;
    div.style.left = e.clientX + "px";
    div.style.top = e.clientY + "px";
    div.innerHTML = tekst;
    div.id = "tooltip_" + veldnaam;
    document.getElementsByTagName("body")[0].appendChild(div);
    return false;
  }

  const TekstVeldMetLabel = (props) => {
    var editable = props.editable;
    if (viewMode.current == "View") {
      editable = false;
    }
    if (props.multiline) {
      return (
        <div style={{ float: "left", clear: "none", display: "inline-flex" }}>
          <label
            style={{
              display: "inline-block",
              width: "150px",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            {props.alias}
            <div class="tooltip">
              {props.tooltip != undefined && props.tooltip != "" ? (
                <HelpOutlineIcon
                  onMouseEnter={(e) =>
                    tooltipEnter(e, props.tooltip, props.field)
                  }
                  onMouseLeave={(e) => tooltipLeave(e, props.field)}
                  fontSize="small"
                  color="primary"
                  style={{ fontSize: "0.75rem" }}
                ></HelpOutlineIcon>
              ) : (
                <div></div>
              )}
            </div>
          </label>

          <textarea
            style={{
              display: "inline-block",
              width: "200px",
              padding: "10px",
              textAlign: "left",
              margin: "5px",
              verticalAlign: "top",
              fontFamily: "Roboto, Helvetic, Arial, sans-serif",
            }}
            autoComplete="off"
            disabled={!editable}
            rows="5"
            type="text"
            name={props.field}
            id={props.field}
            defaultValue={props.waarde}
            onFocus={onFocus}
            onBlur={onLeave}
          ></textarea>
        </div>
      );
    } else {
      return (
        <div style={{ float: "left", clear: "none", display: "inline-flex" }}>
          <label
            style={{
              display: "inline-block",
              width: "150px",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            {props.alias}
            <div class="tooltip">
              {props.tooltip != undefined && props.tooltip != "" ? (
                <HelpOutlineIcon
                  onMouseEnter={(e) =>
                    tooltipEnter(e, props.tooltip, props.field)
                  }
                  onMouseLeave={(e) => tooltipLeave(e, props.field)}
                  fontSize="small"
                  color="primary"
                  style={{ fontSize: "0.75rem" }}
                ></HelpOutlineIcon>
              ) : (
                <div></div>
              )}
            </div>
          </label>

          <input
            style={{
              display: "inline-block",
              width: "200px",
              padding: "10px",
              textAlign: "left",
              margin: "5px",
            }}
            autoComplete="off"
            disabled={!editable}
            type="text"
            name={props.field}
            id={props.field}
            defaultValue={props.waarde}
            onFocus={onFocus}
            onBlur={onLeave}
          ></input>
        </div>
      );
    }
  };

  const PlanAttributen = (propsdetail) => {
    //config_editplan.json bevat alle velden die mogelijk zijn
    //per provincie kan het voorkomen dat een veld niet getoond moet worden
    //met de getField-aanroep, halen we het veld op uit de view
    //als dit veld niet voorkomt, gaan we dit veld ook niet tonen
    return JSONEditPlan[propsdetail.categorie].map((veld, index) => {
      var waarde = "";
      if (
        ctxSelectedPlan !== null &&
        ctxSelectedPlan.attributes[veld.field] !== undefined
      ) {
        waarde = ctxSelectedPlan.attributes[veld.field];
      }
      var field = getField(veld.field);
      if (field == null) {
        //Veld komt blijkbaar niet voor in het view van de provincie, dus niet tonen
        console.log("EditPlangegevens-field not found in view: ", veld.field);
        return null;
      }
      //console.log(field);

      //er passen ongeveer 6 velden onder elkaar
      //TODO: uitzoeken of dit automatisch kan met flex columns vertical
      if (index >= propsdetail.van && index < propsdetail.tot) {
        if (
          veld.field != "gemeente" &&
          veld.field != "regio" &&
          veld.field != "provincie" &&
          field.domain != null
        ) {
          return (
            <DropDown
              id={veld.field}
              alias={field.alias}
              value={waarde}
              domain={field.domain.codedValues}
              editable={field.editable}
              tooltip={getTooltip(veld.field)}
            ></DropDown>
          );
        } else {
          return (
            <TekstVeldMetLabel
              editable={
                veld.field != "gemeente" &&
                veld.field != "regio" &&
                veld.field != "provincie"
                  ? field.editable
                  : false
              }
              alias={field.alias}
              field={veld.field}
              waarde={waarde}
              multiline={veld.multiline}
              tooltip={getTooltip(veld.field)}
            ></TekstVeldMetLabel>
          );
        }
      } else {
        return null;
      }
    });
  };

  const controleerGegevens = () => {
    console.log("Controleer gegevens: ", ctxSettings["verplichtevelden"]);
    var verplichtevelden = ctxSettings["verplichtevelden"].split(",");
    if (verplichtevelden.length == 0) return;
    var tekst = "De volgende velden moeten nog gevuld worden: ";
    for (let index = 0; index < verplichtevelden.length; index++) {
      const veldnaam = verplichtevelden[index].trim();
      if (
        ctxSelectedPlan.attributes[veldnaam] == "" ||
        ctxSelectedPlan.attributes[veldnaam] == undefined ||
        ctxSelectedPlan.attributes[veldnaam] == null
      ) {
        var field = getField(veldnaam);
        if (field == null) tekst = tekst + " " + veldnaam;
        else tekst = tekst + " " + field.alias + " , ";
      }
    }
    setOpenMessageBoxTekst(tekst);
    setOpenMessageBox(true);
  };

  const getTooltip = (fieldname) => {
    for (let index = 0; index < ctxTooltips.length; index++) {
      if (ctxTooltips[index][0] == fieldname) return ctxTooltips[index][1];
    }
    return "";
  };

  const TotalenInfo = () => {
    if (infoTotaal.length == 0) return <div></div>;
    //infoTotaal is een array van 4 stuks:  gerealiseerd_bouw, gerealiseerd_sloop, resterend_bouw, resterend_sloop
    //ieder onderdeel bevat een array van jaren met daarin aantallen
    //dus bv gerealiseerd_bouw={2021: 64, 2022: 0, 2023: 0}
    //en bv resterend_bouw={2021: 0, 2022: 35, 2023: 35}

    //We willen:
    //-alle jaren gerealiseerd_bouw opgeteld hebben
    //-alle jaren gerealiseerd_sloop opgeteld hebben
    //-alle jaren resterend_bouw opgeteld hebben
    //-alle jaren resterend_sloop opgeteld hebben
    let gerealiseerd_bouw = 0;
    let gerealiseerd_sloop = 0;
    let resterend_bouw = 0;
    let resterend_sloop = 0;
    Object.values(infoTotaal[0]).forEach((jaarvalue) => {
      gerealiseerd_bouw += jaarvalue;
    });
    Object.values(infoTotaal[1]).forEach((jaarvalue) => {
      gerealiseerd_sloop += jaarvalue;
    });
    Object.values(infoTotaal[2]).forEach((jaarvalue) => {
      resterend_bouw += jaarvalue;
    });
    Object.values(infoTotaal[3]).forEach((jaarvalue) => {
      resterend_sloop += jaarvalue;
    });
    return (
      <table>
        <tr>
          <th style={{ width: "100px" }}></th>
          <th style={{ width: "100px" }}>Bouw</th>
          <th style={{ width: "100px" }}>Sloop</th>
        </tr>
        <tr>
          <td>Gerealiseerd</td>
          <td>{gerealiseerd_bouw}</td>
          <td>{gerealiseerd_sloop}</td>
        </tr>
        <tr>
          <td>Resterend</td>
          <td>{resterend_bouw}</td>
          <td>{resterend_sloop}</td>
        </tr>
        <tr>
          <td>Totaal</td>
          <td>{gerealiseerd_bouw + resterend_bouw}</td>
          <td>{gerealiseerd_sloop + resterend_sloop}</td>
        </tr>
      </table>
    );
  };

  const responseMessageBox = (value) => {
    setOpenMessageBox(false);
  };

  const responseModalDialog = (value) => {
    console.log("Planning doorschruiven: ", value);
    setOpenModal(false);
    if (value) {
      var eerste = parseInt(ctxSelectedPlan.attributes["oplevering_eerste"]);
      var laatste = parseInt(ctxSelectedPlan.attributes["oplevering_laatste"]);
      if (isNaN(eerste) || isNaN(laatste)) {
        alert("Update eerste/laatste niet mogelijk.");
        return;
      }
      ctxSelectedPlan.attributes["oplevering_eerste"] = eerste + 1;
      ctxSelectedPlan.attributes["oplevering_laatste"] = laatste + 1;
      //Update de eerste en laatste oplevering in de database
      updateFeatureAttr(
        ctxFeaturelayerUrl,
        ctxSelectedPlan.attributes[window.Objectid],
        "oplevering_eerste",
        eerste + 1,
        ctxUserSession
      );
      updateFeatureAttr(
        ctxFeaturelayerUrl,
        ctxSelectedPlan.attributes[window.Objectid],
        "oplevering_laatste",
        laatste + 1,
        ctxUserSession
      );

      shiftDetailRecords(
        ctxSelectedPlan.attributes,
        ctxDetailTableUrl,
        ctxUserSession,
        callbackSetInvalid
      );
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: window.Color }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Basis" {...a11yProps(0)} />
          <Tab label="Status" {...a11yProps(1)} />
          <Tab label="Kwalitatief" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div style={{ overflow: "auto" }}>
          <table style={{ tableLayout: "fixed" }}>
            <tr style={{ verticalAlign: "top" }}>
              <td style={{ width: "33%", textAlign: "left" }}>
                <PlanAttributen
                  id={1}
                  categorie="Basis"
                  van={0}
                  tot={6}
                ></PlanAttributen>
              </td>
              <td style={{ width: "33%", textAlign: "left" }}>
                <PlanAttributen
                  id={2}
                  categorie="Basis"
                  van={6}
                  tot={11}
                ></PlanAttributen>
              </td>
              <td style={{ width: "33%", textAlign: "left" }}>
                <PlanAttributen
                  id={3}
                  categorie="Basis"
                  van={11}
                  tot={20}
                ></PlanAttributen>
              </td>
            </tr>
          </table>
        </div>
        <div style={{ float: "right" }}>
          <Link
            style={{
              color: window.Color,
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "underline",
              display: viewMode.current == "View" ? "none" : "",
            }}
            href="#"
            onClick={() => setOpenModal(true)}
            variant="body2"
          >
            Verschuif planning 1 jaar vooruit
          </Link>
          <Link
            style={{
              color: window.Color,
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "underline",
              display: viewMode.current == "View" ? "none" : "",
            }}
            href="#"
            variant="body2"
            onClick={controleerGegevens}
          >
            Controleer gegevens
          </Link>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div style={{ overflow: "auto" }}>
          <table style={{ tableLayout: "fixed" }}>
            <tr style={{ verticalAlign: "top" }}>
              <td style={{ width: "33%", textAlign: "left" }}>
                <PlanAttributen
                  categorie="Status"
                  van={0}
                  tot={4}
                ></PlanAttributen>
              </td>
              <td style={{ width: "33%", textAlign: "left" }}>
                <PlanAttributen
                  categorie="Status"
                  van={4}
                  tot={8}
                ></PlanAttributen>
              </td>
              <td style={{ width: "33%", textAlign: "left" }}>
                <PlanAttributen
                  categorie="Status"
                  van={8}
                  tot={20}
                ></PlanAttributen>
              </td>
            </tr>
          </table>
        </div>
        <div style={{ float: "right" }}>
          <Link
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "underline",
              display: viewMode.current == "View" ? "none" : "",
            }}
            href="#"
            variant="body2"
            onClick={controleerGegevens}
          >
            Controleer gegevens
          </Link>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div style={{ overflow: "auto" }}>
          <table style={{ tableLayout: "fixed" }}>
            <tr style={{ verticalAlign: "top" }}>
              <td style={{ width: "33%", textAlign: "left" }}>
                <PlanAttributen
                  categorie="Kwalitatief"
                  van={0}
                  tot={5}
                ></PlanAttributen>
              </td>
              <td style={{ width: "33%", textAlign: "left" }}>
                <PlanAttributen
                  categorie="Kwalitatief"
                  van={5}
                  tot={9}
                ></PlanAttributen>
              </td>
              <td style={{ width: "33%", textAlign: "left" }}>
                {" "}
                <PlanAttributen
                  categorie="Kwalitatief"
                  van={9}
                  tot={20}
                ></PlanAttributen>
              </td>
            </tr>
          </table>
        </div>
        <div style={{ float: "right" }}>
          <Link
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "underline",
              display: viewMode.current == "View" ? "none" : "",
            }}
            href="#"
            variant="body2"
            onClick={controleerGegevens}
          >
            Controleer gegevens
          </Link>
        </div>
      </TabPanel>
      <ModalDialog
        open={openModal}
        title={"Planning doorschuiven?"}
        question="Hiermee wordt de complete planning 1 jaar doorgeschoven! Dit kan niet automatisch ongedaan gemaakt worden. Wilt u doorgaan?"
        handleClose={responseModalDialog}
      ></ModalDialog>
      <MessageBox
        open={openMessageBox}
        title={"Verplichte velden"}
        question={openMessageBoxTekst}
        handleClose={responseMessageBox}
      ></MessageBox>
    </div>
  );
}
