//https://www.npmjs.com/package/@esri/arcgis-rest-request
import { request } from "@esri/arcgis-rest-request";

export async function searchSettingsLayer(
  onlineurl,
  orgID,
  callbackfunc,
  token
) {
  const tag = window.SettingTagId; //tag for Settings

  const params = {
    q: "(PlanRegistratieSettings) orgid:" + orgID,
    f: "json",
    filter: "tags:" + tag,
    token: token,
    searchFields: "title,tags",
  };
  const paramVals = [];
  for (const k in params) {
    const val = encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    paramVals.push(val);
  }
  const url = `${onlineurl}/search/suggest=${tag}?${paramVals.join("&")}`;

  request(url, {
    responseType: "json",
  }).then(function (response) {
    //console.log(response);
    let gevonden = false;
    for (let index = 0; index < response.results.length; index++) {
      if (response.results[index].type === "Feature Service") {
        gevonden = true;
        console.log("PlanRegistratieSettings: ", response.results[index]);
        callbackfunc(response.results[index].url);
        break;
      }
    }
    if (gevonden === false) {
      alert("PlanRegistratieSettings niet gevonden: " + tag);
    }
  });
}
